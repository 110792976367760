{
  "name": "@mirai/core",
  "version": "0.4.280",
  "source": "src/index.js",
  "repository": "https://gitlab.com/miraicorp/dev/frontend/core",
  "author": "JΛVI <hello@soyjavi.com>",
  "license": "MIT",
  "scripts": {
    "setup": "rm -rf node_modules && rm -rf yarn.lock && yarn install --force",
    "start": "node scripts/start.js development",
    "build:npm": "node scripts/build.js",
    "build:bundle": "node scripts/bundle.js",
    "build:stats": "source-map-explorer 'dist/**/*.js'",
    "serve": "serve dist/site -p 8080 -u -n",
    "lint": "eslint --cache --fix --format codeframe --ext .jsx,.js src",
    "test": "node scripts/test.js --watchAll=false --silent",
    "test:coverage": "node scripts/test.js --coverage --coverageReporters=text --detectOpenHandles --forceExit --silent",
    "test:watch": "node scripts/test.js",
    "test:e2e": "yarn playwright test --ui",
    "test:e2e:ci": "CI=true yarn playwright test",
    "test:e2e:docker:ci": "docker build -t playwright-tests . && docker run --shm-size=1gb --rm -v $(pwd)/__tests__:/app/__tests__ -v $(pwd)/test-results:/app/test-results -v $(pwd)/playwright-report:/app/playwright-report playwright-tests yarn test:e2e:ci",
    "test:e2e:recorder": "yarn playwright codegen --device='iPhone 12'",
    "test:e2e:report": "yarn playwright show-report",
    "pipeline": "yarn lint && yarn test",
    "release": "yarn build:npm && yarn version --patch && yarn publish --access public"
  },
  "dependencies": {
    "@mirai/data-sources": "^0.2",
    "@mirai/locale": "^0.3",
    "@mirai/services": "^0.3",
    "@mirai/ui": "^2.1",
    "@react-icons/all-files": "https://github.com/react-icons/react-icons/releases/download/v5.0.1/react-icons-all-files-5.0.1.tgz",
    "@sentry/react": "^7.57.0",
    "prop-types": "^15.8.1",
    "react": "^17.0.2",
    "react-dom": "^17.0.2"
  },
  "devDependencies": {
    "@babel/cli": "7.19.3",
    "@babel/core": "7.20.5",
    "@babel/preset-env": "7.20.2",
    "@babel/preset-react": "7.18.6",
    "@mirai/eslint": "0.1.3",
    "@parcel/transformer-inline-string": "2.11.0",
    "@playwright/test": "^1.49.1",
    "@sentry/cli": "^2.38.1",
    "@testing-library/react": "12.1.5",
    "@testing-library/react-hooks": "7.0.2",
    "jest-fetch-mock": "^3.0.3",
    "parcel": "2.11.0",
    "process": "0.11.10",
    "react-scripts": "5.0.1",
    "serve": "14.2.0",
    "source-map-explorer": "2.5.3",
    "wouter": "^2.8.1"
  },
  "engines": {
    "node": ">=18.18.0"
  },
  "resolutions": {
    "@mirai/data-sources": "^0.2",
    "@mirai/locale": "^0.3",
    "@mirai/services": "^0.3",
    "@mirai/ui": "^2.1",
    "@react-icons/all-files": "https://github.com/react-icons/react-icons/releases/download/v5.0.1/react-icons-all-files-5.0.1.tgz",
    "react": "^17.0.2",
    "react-dom": "^17.0.2"
  },
  "browserslist": [
    ">0.2%",
    "not dead",
    "not op_mini all"
  ],
  "targets": {
    "default": {
      "context": "browser",
      "includeNodeModules": true
    }
  },
  "@parcel/transformer-js": {
    "inlineEnvironment": true
  }
}
